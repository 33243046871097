<template>
<div>
    <v-row align="center" class="mb-3">
        <v-spacer />
        <v-col cols="12" md="3">
            <v-text-field v-debounce:500ms="getItems" v-model="$global.state.filter.search" label="ابحث هنا"
                color="indigo" hide-details prepend-inner-icon="search" outlined dense filled />
        </v-col>

        <v-col cols="12" md="3">
            <v-autocomplete @input="getItems()" clearable v-model="$global.state.provinceId" item-text="name"
                item-value="id" :items="$global.state.provinces" color="indigo" label="المحافظة"
                :loading="$global.state.loading" no-data-text="لا توجد بيانات" hide-details prepend-inner-icon="place"
                outlined dense filled />
        </v-col>
    </v-row>

    <v-card id="card">
        <v-data-table class="mytable" :items="$global.state.clients" :loading="$global.state.loading" :headers="headers"
            :search="$global.state.filter.search" hide-default-footer loading-text="جاري تحميل البيانات"
            no-data-text="لا توجد بيانات" :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'"
            :items-per-page="$global.state.filter.pageSize">
            <template v-slot:item.id="{ item }">
                <span>{{ $global.state.clients.indexOf(item) + 1 }}</span>
            </template>

            <template v-slot:item.fullName="{ item }">
                <div v-text="item.fullName"></div>
                <span class="font-small info--text"> {{ item.tab ? item.tab.name : '' }}</span>
            </template>

            <template v-slot:item.phoneNumber="{ item }">
                <div dir="ltr">{{ item.phoneNumber | phone }}</div>
            </template>

            <template v-slot:item.isBlock="{ item }">
                <v-chip v-if="item.isBlock" small dark color="red lighten-1">محظور</v-chip>
                <v-chip v-else dark small color="green lighten-1">فعال</v-chip>
            </template>

            <template v-slot:item.rating="{ item }">
                <star-rating v-model="item.rating" inactive-color="#FFFFFF" :padding="1" :rounded-corners=true
                    :border-width="2" :increment="0.01" :fixed-points="2" :star-size="20" :show-rating="false"
                    :rtl="true" :max-rating=5 :read-only=true></star-rating>
                <!-- <v-rating v-model="item.rating" @input="ratingClient(item.id, item.rating)" color="primary"
                    background-color="grey lighten-1" length="5" dense hover>{{ item.rating }}</v-rating> -->
            </template>

            <template v-slot:item.created="{ item }">
                <div>{{ new Date(item.created).toISOString().substring(0, 10) }}</div>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="toggleBlock(item)" v-on="on" icon>
                            <v-icon :color="item.isBlock ? 'grey darken-2' : 'red'">{{ item.isBlock ? 'mdi-lock-open' :
                                'mdi-cancel' }}</v-icon>
                        </v-btn>
                    </template>
                    <span>{{ item.isBlock ? 'إلغاء الحظر' : 'حظر' }}</span>
                </v-tooltip>

                <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn @click.stop="deleteItem(item.id, index)" v-on="on" icon>
                                <v-icon color="error">delete</v-icon>
                            </v-btn>
                        </template>
                        <span>حذف</span>
                    </v-tooltip> -->
            </template>
        </v-data-table>
    </v-card>

    <div class="d-flex justify-space-between py-3">
        <v-spacer></v-spacer>
        <Pagination />
    </div>
</div>
</template>

<script>
import StarRating from 'vue-star-rating'
export default {
    components: {
        StarRating
    },
    data() {
        return {
            rating: 0,
            headers: [
                {
                    text: "#",
                    value: "id",
                },
                {
                    text: "الإسم الكامل",
                    value: "fullName",
                },
                {
                    text: "رقم الهاتف",
                    value: "phoneNumber",
                    align: "right",
                },
                {
                    text: "الحالة",
                    value: "isBlock",
                },
                {
                    text: " المحافظة",
                    value: "province.name",
                },
                {
                    text: " العنوان",
                    value: "addres",
                },
                {
                    text: "التقييم",
                    value: "rating",
                    align: "right",
                },
                {
                    text: "الطلبات",
                    value: "ordersCount",
                    align: "center",
                },
                {
                    text: "تاريخ التسجيل",
                    value: "created",
                },
                {
                    text: "الاجراءات",
                    value: "actions",
                },
            ],
        };
    },

    mounted() {
        this.$global.state.filter.pageSize = 100;

        this.getItems();
        this.$eventBus.$on(`refresh`, () => {
            this.getItems();
        });
    },

    beforeDestroy() {
        this.$eventBus.$off(`refresh`);
    },

    methods: {
        getItems() {
            this.$global.dispatch(
                `get${this.$route.meta.endPoint}`,
                this.$route.meta.role
            );
        },

        async ratingClient(clientId, rating) {
            // console.log(rating);
            this.$global.state.loading = true;
            await this.$http
                .put(`Clients/rating/${clientId}`, { rating })
                .then((res) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `تم تقييم الزبون بنجاح  ${rating}  من  5`,
                        type: "success",
                    });
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        async toggleBlock(item) {
            this.$global.state.loading = true;
            await this.$http
                .delete(`Clients/toggleBlocked/${item.id}`)
                .then((res) => {
                    this.getItems();
                    this.$store.dispatch("toastification/setToast", {
                        message: `${res.data.message}`,
                        type: "success",
                    });
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        async deleteItem(id, index) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`${this.$route.meta.endPoint}`, id)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${res.data.message}`,
                                type: "success",
                            });
                            this.$global.state.clients.splice(index, 1);
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `${err.data.message}`,
                                type: "error",
                            });
                        });
                }
            });
        },
    },
};
</script>
